import React from "react";
import styled from "styled-components";

const Terms = () => {
  return (
    <Wrapper>
      <h1>Terms & Conditions for LeadsFinder</h1>
      <p>
        Welcome to LeadsFinder! These Terms & Conditions govern your use of our
        website and services. By accessing or using the service, you agree to be
        bound by these terms.
      </p>

      <h1>1. Subscription</h1>

      <p>
        LeadsFinder offers a subscription-based service that provides full
        access to our database in the form and shape of our interface, which
        includes information about companies in the UK. Upon subscribing, you
        will immediately gain access to all features and data available on the
        service.
      </p>

      <h1>2. Payment</h1>

      <p>
        Subscription fees are charged in advance on a recurring basis and are
        non-refundable, except as described in the refund policy below. By
        subscribing, you authorize us to charge the subscription fee through
        your selected payment method.
      </p>

      <h1>3. Cancellation and Refund Policy</h1>

      <p>
        You may cancel your subscription at any time. Upon cancellation, your
        subscription will remain active until the end of the current billing
        cycle.
      </p>

      <p>
        If you wish to request a refund, please contact our customer service.
        Refunds are processed at our discretion. If granted, we will cancel your
        subscription immediately and refund your latest paid amount.
      </p>

      <h1>4. Use of Service</h1>

      <p>
        You are granted a non-exclusive, non-transferable, revocable license to
        access and use our service strictly in accordance with these terms. As a
        condition of your use of the site, you warrant that you will not use the
        site for any purpose that is unlawful or prohibited by these terms.
      </p>

      <h1>5. Intellectual Property Rights</h1>

      <p>
        All content included on the site, such as text, graphics, logos, images,
        as well as the compilation thereof, and any software used on the site,
        is the property of LeadsFinder or its suppliers and protected by
        copyright and other laws that protect intellectual property and
        proprietary rights.
      </p>

      <h1>6. User Conduct</h1>

      <p>
        You agree to use the service only for lawful purposes and in a way that
        does not infringe the rights of, restrict or inhibit anyone else's use
        and enjoyment of the service.
      </p>

      <h1>7. Disclaimer of Warranties</h1>

      <p>
        The service is provided "AS IS" and "AS AVAILABLE" without any
        warranties of any kind. LeadsFinder expressly disclaims any and all
        warranties, whether express or implied, including, but not limited to,
        the implied warranties of merchantability, fitness for a particular
        purpose, and non-infringement.
      </p>

      <h1>8. Limitation of Liability</h1>

      <p>
        LeadsFinder shall not be liable for any direct, indirect, incidental,
        special, consequential or exemplary damages, including but not limited
        to damages for loss of profits, goodwill, use, data or other intangible
        losses resulting from the use of or inability to use the service.
      </p>

      <h1>9. Changes to Terms</h1>

      <p>
        LeadsFinder reserves the right, in its sole discretion, to change the
        Terms under which the service is offered. The most current version of
        the Terms will supersede all previous versions.
      </p>

      <h1>10. Contact Us</h1>

      <p>
        If you have any questions about these Terms & Conditions, please contact
        us.
      </p>
    </Wrapper>
  );
};

export default Terms;

const Wrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;

  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  // mobile
  @media (max-width: 768px) {
    padding: 10px;
  }
`;
