import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../supabase";
import moment from "moment";
import sicList from "../data/sicList";
import { SLUGS } from "../helpers/sicHelpers";

import styled from "styled-components";
import useUserStore from "../stores/userStore";
import {
  Bell,
  Check,
  CheckCircle,
  Info,
} from "feather-icons-react/build/IconComponents";
import PrimaryButton from "../components/buttons/primaryButton";
import { v4 as uuidv4 } from "uuid";
import Pricing from "./pricing";

const MembershipInfo = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;
  width: 100%;
  /* max-width: 600px; */

  h3 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  svg {
    margin-right: 10px;
    vertical-align: middle;
    stroke: #635bff;
    stroke-width: 4;
  }

  // mobile
  @media (max-width: 600px) {
    width: auto;
  }
`;

const FilterInfoWrapper = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f0f0f0;

  width: 100%;
  max-width: 800px;

  h2 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
    padding: 1px 5px;
  }

  svg {
    vertical-align: middle;
    margin-right: 10px;
  }

  /* svg {
    margin-right: 10px;
    vertical-align: middle;
    stroke: #635bff;
    stroke-width: 4;
  } */

  // mobile
  @media (max-width: 600px) {
    width: auto;
  }
`;

const TopRightInfo = styled.div`
  display: flex;
  justify-content: flex-end;

  svg {
    vertical-align: middle;
    cursor: pointer;
    stroke-width: 2px;
    stroke: #817e7e;
  }

  svg:hover {
    stroke: #4f46e5;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;

  // mobile
  /* @media (max-width: 600px) {
    padding: 20px 10px;
  } */
`;

const PaymentLink = styled.a`
  margin-top: 20px;
  display: inline-block;
  background-color: #635bff; /* Stripe's brand color, you can change this */
  color: white !important;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #4f46e5;
  }
`;

const PaymentInfo = styled.div`
  margin-bottom: 20px;
`;

const PricePlan = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
`;

const PricePlanPrice = styled.div`
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 10px;

  span {
    font-size: 2.8rem;
  }
`;

const PricePlanFeatures = styled.ul`
  margin-bottom: 10px;
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 5px;

    svg {
      margin-right: 10px;
      vertical-align: middle;
      stroke: #635bff;
      stroke-width: 4;
    }
  }
`;

const FilterRow = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 3px 10px;

  p {
    flex: 1;
    &:hover {
      text-decoration: underline;
    }
  }

  // mobile
  @media (max-width: 600px) {
    // Ellipsis for long text on mobile, first p tag

    p:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      font-size: 0.9rem;
    }

    ${PrimaryButton} {
      font-size: 0.9rem;
      width: fit-content;
    }
  }
`;

const EmptyStateWrapper = styled.div`
  a {
    color: #635bff;
    text-decoration: underline;
  }

  a:hover {
    color: #4f46e5;
    text-decoration: underline;
  }
`;

const LogoutButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 100px;
`;

const Account = (props) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  const { user, userFilterSubscriptions, setUserFilterSubscriptions } =
    useUserStore();

  useEffect(() => {
    // take stripe session id from url and check if it was successful
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get("session");

    if (sessionId) {
      getStripeSession(sessionId);
    } else {
      setIsLoading(false);
    }
  }, []);

  const getStripeSession = async (sessionId) => {
    try {
      const { data, error } = await supabase.functions.invoke(
        "stripe-verify-session",
        {
          body: { sessionId: sessionId },
        }
      );

      if (error) {
        console.log("error", error);
        return;
      }

      console.log("data", data);

      if (user) {
        await supabase
          .from("users")
          .update({ stripe_customer_id: data.customer })
          .eq("id", user.id);

        setIsLoading(false);
      }

      window.location.href = "/account";
    } catch (error) {
      setIsLoading(false);
    }
  };

  const timestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString();
  };

  const removeFilter = async (uuid) => {
    const { error: deleteError } = await supabase
      .from("user_companies_watch")
      .delete()
      .eq("uuid", uuid);

    if (deleteError) {
      console.log("error", deleteError);
      return;
    }

    // fetch new filters
    const { data: newFilters, error: fetchError } = await supabase
      .from("user_companies_watch")
      .select("*")
      .eq("user_id", user.uid);

    if (fetchError) {
      console.log("error", fetchError);
      return;
    }

    setUserFilterSubscriptions(newFilters);
  };

  const getSicFromNumber = (sicNumber) => {
    const sic = sicList.find((section) => {
      return section.items.find((item) => {
        return item.match(/\d+/)[0] === sicNumber;
      });
    });

    return sic;
  };

  const renderSubscriptionStatusOrLink = () => {
    if (user.paid_until && moment(user.paid_until) > moment()) {
      return (
        <div>
          <MembershipInfo>
            <h3>
              <CheckCircle size={20} />
              Member
            </h3>

            <p>
              Your membership will auto-renew at{" "}
              {timestampToDate(user.paid_until)}
            </p>

            <PrimaryButton
              warning
              onClick={async () => {
                const confirmation = window.confirm(
                  "Are you sure you want to cancel your subscription?"
                );
                if (confirmation === true) {
                  // Send post request to /cancel-subscription
                  const res = await supabase.functions.invoke(
                    "stripe-cancel-subscription",
                    {
                      body: { uid: user.uid },
                    }
                  );

                  if (res.error) {
                    console.log("error", res.error);
                    return;
                  }

                  alert("Your subscription has been cancelled");

                  // TODO: do not navigate, implement non paid state
                  navigate("/");
                }
              }}
            >
              Cancel subscription
            </PrimaryButton>
          </MembershipInfo>

          <p></p>

          {/* TODO - MAKE CLICK ON ROW (EXCEPT CHECKBOX) NAVIGATE TO /by-industry SLUG */}
          <FilterInfoWrapper>
            <TopRightInfo>
              <Info
                size={20}
                onClick={() =>
                  alert(
                    "You will receive an email once a month when a new company matches any these filters"
                  )
                }
              />
            </TopRightInfo>

            <h2>
              <Bell size={25} />
              Active target audiences
            </h2>
            {userFilterSubscriptions && userFilterSubscriptions.length > 0 && (
              <p>
                You will recieve exported data by mail when new companies
                matches these filters
              </p>
            )}
            {userFilterSubscriptions.map((filter) => {
              const sicNumber = filter.sic_text.split(" ")[0];
              const sic = getSicFromNumber(sicNumber);
              const slug = SLUGS.find(
                (slug) => slug.letter === sic.letter.toLowerCase()
              );

              return (
                <FilterRow key={uuidv4()}>
                  <p
                    onClick={() =>
                      navigate(`/by-industry/${slug.name}/sic/${sicNumber}`)
                    }
                  >
                    {filter.sic_text}
                  </p>
                  <PrimaryButton
                    xsmall
                    onClick={() => removeFilter(filter.uuid)}
                  >
                    Remove
                  </PrimaryButton>
                </FilterRow>
              );
            })}

            {userFilterSubscriptions.length === 0 && (
              <EmptyStateWrapper>
                <p>You have no active audiences</p>
                <Link to="/by-industry">Add some</Link>
              </EmptyStateWrapper>
            )}
          </FilterInfoWrapper>

          <PrimaryButton
            onClick={() => {
              supabase.auth.signOut();
              navigate("/");
            }}
          >
            Log out
          </PrimaryButton>
        </div>
      );
    }
    return (
      <>
        {/* <PaymentInfo>
          <h2>Unlock all features</h2>
        </PaymentInfo> */}

        {/* <PricePlan>

          <PricePlanPrice>
            <span>£49</span> /year
          </PricePlanPrice>

          <PricePlanFeatures>
            <li>
              <Check size={20} />
              Full access to all the data
            </li>
            <li>
              <Check size={20} />
              Subscribe to multiple filters and get notified on updates
            </li>
            <li>
              <Check size={20} />
              Export lists of companies
            </li>
            <li>
              <Check size={20} />
              Email support 24/7
            </li>
          </PricePlanFeatures>

          <PaymentLink
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event) => {
              // prevent default and track conversion, then open link

              event.preventDefault();

              trackConversion();

              window.open(
                "https://buy.stripe.com/28obJv6mIbfGeze7sx",
                "_blank"
              );
            }}
          >
            Get started
          </PaymentLink>
        </PricePlan> */}

        <Pricing tagline={"Unlock all features"} />

        <LogoutButtonWrapper>
          <PrimaryButton
            onClick={() => {
              supabase.auth.signOut();
              navigate("/");
            }}
          >
            Log out
          </PrimaryButton>
        </LogoutButtonWrapper>
      </>
    );
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={{ marginTop: "50px", padding: "0px 20px" }}>
      {user ? (
        <Wrapper>
          {/* <h3>{user.email}</h3> */}
          {/* <button
              onClick={() => {
                supabase.auth.signOut();
                localStorage.clear();

                // route to home page
                navigate("/");
              }}
            >
              Log out
            </button> */}

          {renderSubscriptionStatusOrLink()}
        </Wrapper>
      ) : (
        <Wrapper>
          <h3>You are not logged in</h3>

          <PrimaryButton
            onClick={() => {
              navigate("/login");
            }}
          >
            Log in
          </PrimaryButton>
        </Wrapper>
      )}
    </div>
  );
};

export default Account;
