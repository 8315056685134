import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const Card = styled.div`
  /* border: 1px solid #eee; */
  border-radius: 5px;
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  /* align-items: center; */
`;

const CompanyName = styled.h2`
  margin: 0;
  font-size: 1.2rem;
`;

const CompanyInfo = styled.p`
  margin: 10px 0;
`;

const CompanyCard = ({ company }) => {
  // const renderSicCodes = () => {
  //   let sicCodes = [];
  //   for (let i = 1; i <= 4; i++) {
  //     if (company.SICCode && company.SICCode[`SicText_${i}`]) {
  //       sicCodes.push(
  //         <CompanyInfo key={i}>
  //           SIC Code {i}: {company.SICCode[`SicText_${i}`]}
  //         </CompanyInfo>
  //       );
  //     }
  //   }
  //   return sicCodes;
  // };

  return (
    <Card>
      <CompanyName>
        <Link to={`/company/${company.CompanyNumber}`}>
          {company.CompanyName}
        </Link>
      </CompanyName>
      <CompanyInfo>
        <p>
          <b>Company number:</b> {company.CompanyNumber}
        </p>
        <p>
          <b>Incorporation date:</b> {company.IncorporationDate}
        </p>

        <p>
          <b>City</b> {company.City}
        </p>
      </CompanyInfo>

      <Link to={`/company/${company.CompanyNumber}`}>View more</Link>
    </Card>
  );
};

export default CompanyCard;
