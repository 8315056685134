import React, { useEffect, useState } from "react";
import { supabase } from "../supabase";
import styled from "styled-components";
import { Info } from "feather-icons-react/build/IconComponents";
import { GoogleLoginButton } from "react-social-login-buttons";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  h4 {
    margin: 0 auto;
  }
`;

const StyledInput = styled.input`
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* width: 100%; */
`;

const StyledButton = styled.button`
  padding: 10px 0px;
  margin: 10px 0;
  border: none;
  border-radius: 5px;
  width: 100%;
  background-color: #635bff;
  color: white;
  font-weight: bold;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    color: #666;
    pointer-events: none;
    background-color: #ccc;
  }
`;

const InfoText = styled.p`
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: #888;

  // mobile
  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

const Wrapper = styled.div`
  max-width: 450px;
  margin: 0 auto;

  > div {
    margin-top: 40px;
    border: 1px solid #ccc;
    padding: 1rem 2rem;
    border-radius: 5px;
  }

  h4 {
    margin: 0 auto 20px;
    width: fit-content;
  }

  // mobile
  @media (max-width: 768px) {
    width: 90%;
    padding: 0.5rem;

    h1 {
      font-size: 1.6rem;
    }
  }
`;

const AfterSubmitText = styled.p`
  display: flex;
  align-items: center;
  margin-top: 20px;
  /* color: #3e84ff; */
  font-size: 1.2rem;
  /* background-color: #fff; */
  padding: 20px;
  border-radius: 5px;
  font-weight: bold;
  background-color: #fff;
  color: #635bff;
`;

const OrDivider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;

  span {
    font-size: 1.2rem;
    margin: 0 20px;
    color: #888;
  }
`;

const Line = styled.div`
  flex: 1;
  height: 1px;
  background-color: #ccc;
`;

const Login = () => {
  const [email, setEmail] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);

  // const [googleUser, setGoogleUser] = useState(null);
  // const [profile, setProfile] = useState(null);

  // useEffect(() => {
  //   if (googleUser) {
  //     fetch(
  //       `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${googleUser.access_token}`,
  //           Accept: "application/json",
  //         },
  //       }
  //     )
  //       .then(async (res) => {
  //         return res.json();
  //       })
  //       .then((data) => {
  //         console.log("Data", data);
  //         localStorage.setItem("gUserToken", googleUser.access_token);
  //         setProfile({ ...data, accessToken: googleUser.access_token });
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [googleUser]);

  // console.log("Google User", googleUser);
  // console.log("Profile", profile);

  const signInWithEmail = async (e) => {
    e.preventDefault();
    setHasSubmitted(true);
    console.log("Will sign in with email", email.trim());

    const { data, error } = await supabase.auth.signInWithOtp({
      email: email.trim(),
      options: {
        shouldCreateUser: true,
        emailRedirectTo:
          window.location.protocol + "//" + window.location.host + "/account",
      },
    });

    if (error) {
      console.log("ERROR", error);
    }
  };

  const renderAfterSubmit = () => {
    if (hasSubmitted) {
      return (
        <AfterSubmitText>
          <Info size={20} />
          We've sent a magic link to your email. Click the link to sign in.
        </AfterSubmitText>
      );
    }
  };

  const validEmailRegex = new RegExp(
    // eslint-disable-next-line no-useless-escape
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  );

  const validEmail = validEmailRegex.test(email);

  const loginWithGoogle = async () => {
    await supabase.auth.signInWithOAuth({
      provider: "google",
    });
  };

  return (
    <Wrapper>
      {/* <h2>Sign in with Google</h2> */}
      {/* <button onClick={login}>Sign in with Google 🚀 </button> */}
      {/* <StyledGoogleButton onClick={login} /> */}
      <h1>Log in to your account</h1>

      <p>
        Become a member to set up your target audiences, export unlimited data
        and more.
      </p>

      <div>
        <GoogleLoginButton onClick={loginWithGoogle} />

        <OrDivider>
          <Line />
          <span>or</span>
          <Line />
        </OrDivider>

        {!hasSubmitted && (
          <StyledForm onSubmit={signInWithEmail}>
            <h4>With an email link</h4>

            {/* <label htmlFor="email">Email:</label> */}

            <StyledInput
              placeholder="Your email address"
              type="text"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <StyledButton disabled={!validEmail} type="submit">
              Send link
            </StyledButton>

            <InfoText>
              Your account will be created automatically if you don't have one.
            </InfoText>
          </StyledForm>
        )}

        {renderAfterSubmit()}
      </div>
    </Wrapper>
  );
};

export default Login;
