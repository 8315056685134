import styled from "styled-components";

const Paragraph = styled.p`
  width: 500px;
  font-size: 1.3rem;
  /* margin-top: 50px; */
  line-height: 1.5;

  a {
    color: #ff897e;
    text-decoration: underline;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    width: 90%;
    /* margin: 15px auto; */
  }
`;

export default Paragraph;
