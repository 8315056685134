import React from "react";
import styled from "styled-components";

const Container = styled.div`
  margin: 0 auto;
  padding: 1rem;

  // mobile
  @media (max-width: 600px) {
    width: 90%;
    padding: 0.5rem;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const PrevButton = styled.button`
  padding: 0.5rem;
  margin-right: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
`;

const NextButton = styled.button`
  padding: 0.5rem;
  margin-left: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
`;

const PageNumbers = styled.div`
  display: flex;
  flex-direction: row;
`;

const PageNumber = styled.button`
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;

  ${(props) =>
    props.current &&
    `
    background-color: #ccc;
  `}
`;

const NumberSeperator = styled.span`
  padding: 0.5rem;
  margin-right: 0.5rem;

  @media (max-width: 600px) {
    display: none;
  }
`;

const Pagination = ({ onPaginate, currentPage, totalPages }) => {
  const pageNumbers = [];

  // if there is more than 6 pages, only show the first 3, the last 3, and the current page
  if (totalPages <= 6) {
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <PageNumber
          disabled={currentPage === i}
          key={i}
          onClick={() => onPaginate(i)}
          current={currentPage === i ? 1 : 0}
        >
          {i}
        </PageNumber>
      );
    }
  } else {
    if (currentPage <= 3) {
      for (let i = 1; i <= 4; i++) {
        pageNumbers.push(
          <PageNumber
            disabled={currentPage === i}
            key={i}
            onClick={() => onPaginate(i)}
            current={currentPage === i ? 1 : 0}
          >
            {i}
          </PageNumber>
        );
      }
      pageNumbers.push(<NumberSeperator>...</NumberSeperator>);
      pageNumbers.push(
        <PageNumber
          disabled={currentPage === totalPages}
          key={totalPages}
          onClick={() => onPaginate(totalPages)}
        >
          {totalPages}
        </PageNumber>
      );
    } else if (currentPage > 3 && currentPage < totalPages - 2) {
      pageNumbers.push(
        <PageNumber
          disabled={currentPage === 1}
          key={1}
          onClick={() => onPaginate(1)}
        >
          1
        </PageNumber>
      );
      pageNumbers.push(<NumberSeperator>...</NumberSeperator>);
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        pageNumbers.push(
          <PageNumber
            disabled={currentPage === i}
            key={i}
            onClick={() => onPaginate(i)}
            current={currentPage === i ? 1 : 0}
          >
            {i}
          </PageNumber>
        );
      }
      pageNumbers.push(<NumberSeperator>...</NumberSeperator>);
      pageNumbers.push(
        <PageNumber
          disabled={currentPage === totalPages}
          key={totalPages}
          onClick={() => onPaginate(totalPages)}
        >
          {totalPages}
        </PageNumber>
      );
    } else {
      pageNumbers.push(
        <PageNumber
          disabled={currentPage === 1}
          key={1}
          onClick={() => onPaginate(1)}
        >
          1
        </PageNumber>
      );
      pageNumbers.push(<NumberSeperator>...</NumberSeperator>);
      for (let i = totalPages - 3; i <= totalPages; i++) {
        pageNumbers.push(
          <PageNumber
            disabled={currentPage === i}
            key={i}
            onClick={() => onPaginate(i)}
            current={currentPage === i ? 1 : 0}
          >
            {i}
          </PageNumber>
        );
      }
    }
  }

  return (
    <Container>
      <Row>
        <PrevButton
          disabled={currentPage === 1}
          onClick={() => onPaginate(currentPage - 1)}
        >
          Previous
        </PrevButton>

        <PageNumbers>
          {pageNumbers.map((number) => {
            return number;
          })}
        </PageNumbers>

        <NextButton
          disabled={currentPage === totalPages}
          onClick={() => onPaginate(currentPage + 1)}
        >
          Next
        </NextButton>
      </Row>
    </Container>
  );
};

export default Pagination;
