// description of each SIC code from: https://onsdigital.github.io/dp-classification-tools/standard-industrial-classification/ONS_SIC_hierarchy_view.html
export const SLUGS = [
  {
    letter: "a",
    name: "a-agriculture-forestry-and-fishing",
    description:
      "<p>Exploitation of vegetable and animal natural resources, comprising the activities of growing crops, raising and breeding animals, harvesting timber and other plants, animals or animal products from a farm or their natural habitats.</p>",
  },
  {
    letter: "b",
    name: "b-mining-and-quarrying",
    description:
      "<p>Mining and quarrying include the extraction of minerals occurring naturally as solids (coal and ores), liquids (petroleum) or gases (natural gas). Extraction can be achieved by different methods such as underground or surface mining, well operation, seabed mining etc.</p> <p>This category includes supplementary activities aimed at preparing the crude materials for marketing, for example, crushing, grinding, cleaning, drying, sorting, concentrating ores, liquefaction of natural gas and agglomeration of solid fuels. These operations are often accomplished by the units that extracted the resource and/or others located nearby.</p>",
  },
  {
    letter: "c",
    name: "c-manufacturing",
    description:
      "<p>This broad category involves the transformation of raw materials into finished goods. It includes all aspects of manufacturing from food and beverages to electronics, machinery, textiles, and automobiles.</p>",
  },
  {
    letter: "d",
    name: "d-electricity-gas-steam-and-air-conditioning-supply",
    description:
      "<p>This sector covers the generation, transmission, and distribution of electricity, gas, steam, and air conditioning. It includes both renewable and non-renewable energy sources.</p>",
  },
  {
    letter: "e",
    name: "e-water-supply-sewerage-waste-management-and-remediation-activities",
    description:
      "<p>Focuses on the provision of water supply, the management of sewerage and waste, and the remediation of contaminated land and water. This category also includes recycling activities.</p>",
  },
  {
    letter: "f",
    name: "f-construction",
    description:
      "<p>This section includes general construction and specialised construction activities for buildings and civil engineering works. It includes new work, repair, additions and alterations, the erection of prefabricated buildings or structures on the site and also construction of a temporary nature.</p>",
  },
  {
    letter: "g",
    name: "g-wholesale-and-retail-trade-repair-of-motor-vehicles-and-motorcycles",
    description:
      "<p>This category includes the sale of goods in large quantities (wholesale) or directly to consumers (retail), as well as the repair and maintenance of motor vehicles and motorcycles.</p>",
  },
  {
    letter: "h",
    name: "h-transportation-and-storage",
    description:
      "<p>This section includes the provision of passenger or freight transport, whether scheduled or not, by rail, pipeline, road, water or air and associated activities such as terminal and parking facilities, cargo handling,storage etc. Included in this section is the renting of transport equipment with driver or operator. Also storage etc. Included in this section is the renting of transport equipment with driver or operator. Also included are postal and courier activities.</p>",
  },
  {
    letter: "i",
    name: "i-accommodation-and-food-service-activities",
    description:
      "<p>This section includes the provision of short-stay accommodation for visitors and other travellers and the provision of complete meals and drinks fit for immediate consumption. The amount and type of supplementary services provided within this section can vary widely.supplementary services provided within this section can vary widely.</p>",
  },
  {
    letter: "j",
    name: "j-information-and-communication",
    description:
      "<p>This sector includes activities related to publishing, broadcasting, telecommunications, and information technology services.</p>",
  },
  {
    letter: "k",
    name: "k-financial-and-insurance-activities",
    description:
      "<p>This section includes financial service activities, including insurance, reinsurance and pension funding activities, and activities to support financial services. This section also includes the activities of holding assets, such as activities of holding companies and the activities of trusts, funds and similar financial entities.</p>",
  },
  {
    letter: "l",
    name: "l-real-estate-activities",
    description:
      "<p>This section includes acting as lessors, agents and/or brokers in one or more of the following: selling or buying real estate, renting real estate, providing other real estate services such as appraising real estate or acting as real estate escrow agents. Activities in this section may be carried out on own or leased property and may be done on a fee or contract basis. Also included is the building of structures, combined with maintaining ownership or leasing of such structures.</p> <p>This section includes real estate property managers.</p>",
  },
  {
    letter: "m",
    name: "m-professional-scientific-and-technical-activities",
    description:
      "This category includes specialised professional, scientific and technical activities. These activities require a high degree of training, and make specialised knowledge and skills available to users.",
  },
  {
    letter: "n",
    name: "n-administrative-and-support-service-activities",
    description:
      "<p>This category includes a variety of activities that support general business operations. These activities differ from those in section M, in that their primary purpose is not the transfer of specialised knowledge.</p>",
  },
  {
    letter: "o",
    name: "o-public-administration-and-defence-compulsory-social-security",
    description:
      "<p>This category includes governmental activities related to the administration of the state, defense, and social security programs.</p>",
  },
  {
    letter: "p",
    name: "p-education",
    description:
      "<p>Encompasses all levels of educational services, from pre-school to higher education, as well as vocational and non-vocational training.</p>",
  },
  {
    letter: "q",
    name: "q-human-health-and-social-work-activities",
    description:
      "<p>Includes healthcare services provided by clinics and hospitals, as well as social work services without accommodation, such as daycare and eldercare.</p>",
  },
  {
    letter: "r",
    name: "r-arts-entertainment-and-recreation",
    description:
      "<p>This category includes a wide range of activities catering for various cultural, entertainment and recreational interests of the general public, including live performances, operation of museum sites, gambling, sports and recreation activities.</p>",
  },
  {
    letter: "s",
    name: "s-other-service-activities",
    description:
      "<p>This section (as a residual category) includes the activities of membership organisations, the repair of computers and personal and household goods and a variety of personal service activities not covered elsewhere in the classification.</p>",
  },
  {
    letter: "t",
    name: "t-activities-of-households-as-employers-undifferentiated-goods--and-services-producing-activities-of-households-for-own-use",
    description:
      "<p>Relates to the employment of domestic personnel, such as maids and gardeners, and the production of goods and services for own use by households.</p>",
  },
  {
    letter: "u",
    name: "u-activities-of-extraterritorial-organisations-and-bodies",
    description:
      "<p>Involves activities of international organizations and bodies that are not subject to the jurisdiction of any one country, such as the United Nations.</p>",
  },
];
