import { Info } from "feather-icons-react/build/IconComponents";
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const FadingSpan = styled.span`
  animation: ${(props) => (props.fading === "in" ? fadeIn : fadeOut)} 2s
    ease-in-out;
`;

const TextLooper = ({ texts }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [fading, setFading] = useState("in");

  useEffect(() => {
    const interval = setInterval(() => {
      setFading("out");
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setFading("in");
      }, 2000); // Wait for fade out to complete
    }, 5000); // 4 seconds display + 2 second fade out

    return () => clearInterval(interval);
  }, []);

  return (
    <FadingSpan fading={fading}>
      <Info size={15} color="#0078e7c7" />
      <span
        style={{ marginLeft: "0px" }}
        dangerouslySetInnerHTML={{
          __html: texts[currentTextIndex],
        }}
      ></span>
    </FadingSpan>
  );
};

export default TextLooper;
