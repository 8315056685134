import moment from "moment";

// ------------------------ EXPORTS

export const isMember = (user) => {
  return user && user.paid_until && !dateHasPassed(user.paid_until);
};

// ------------------------- INTERNALS

const dateHasPassed = (timestamp) => {
  const diff = moment().diff(timestamp);
  return diff > 0;
};
