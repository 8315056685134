import React from "react";
import styled from "styled-components";

const Privacy = () => {
  return (
    <Wrapper>
      <h1>Privacy Policy for LeadsFinder</h1>
      <p>Effective date: 2024-02-10</p>
      <p>
        Welcome to LeadsFinder, accessible at https://leadsfinder.co.uk
        ("Website"). At LeadsFinder, we prioritize your privacy and are
        committed to protecting your personal information. This Privacy Policy
        outlines our practices regarding the collection, use, and sharing of
        your information when you use our Service.
      </p>
      <p>
        <b>Acceptance of This Policy</b>
      </p>
      <p>
        By accessing and using our Service, you signify your agreement to the
        terms of this Privacy Policy. If you do not agree with our policies and
        practices, your choice is not to use our Service.
      </p>
      <p>
        <b>Information We Collect</b>
      </p>
      <p>
        When you use our Service, we may collect the following types of
        information:
      </p>
      <ul>
        <li>
          <b>Personal Identification Information:</b> Including, but not limited
          to, your name, email address, and any other information you provide
          when you create an account or interact with our Service.
        </li>

        <li>
          <b>Log Data:</b> Information that your browser sends whenever you
          visit our Service. This Log Data may include details such as your
          computer's Internet Protocol ("IP") address, browser type, browser
          version, our Service pages that you visit, the time and date of your
          visit, the time spent on those pages, and other statistics.
        </li>
        <li>
          <b>Cookies and Tracking Data:</b> We use cookies and similar tracking
          technologies to track the activity on our Service and hold certain
          information. Cookies are files with a small amount of data which may
          include an anonymous unique identifier.
        </li>
      </ul>
      <p>
        <b>Use of Your Information</b>
      </p>
      <p>
        The information we collect helps us to improve our Service and to
        deliver a personalized experience. Specifically, we use your information
        to:
      </p>
      <ul>
        <li>Provide, operate, and maintain our Service</li>

        <li>Improve, personalize, and expand our Service</li>

        <li>Understand and analyze how you use our Service</li>

        <li>Develop new products, services, features, and functionality</li>

        <li>
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the Service, and for marketing and promotional
          purposes
        </li>

        <li>Send you emails</li>

        <li>Find and prevent fraud</li>
      </ul>
      <p>
        <b>Sharing Your Personal Information</b>
      </p>
      <p>
        We may employ third-party companies and individuals to facilitate our
        Service ("Service Providers"), to provide the Service on our behalf, to
        perform Service-related services, or to assist us in analyzing how our
        Service is used. These third parties have access to your Personal
        Information only to perform these tasks on our behalf and are obligated
        not to disclose or use it for any other purpose.
      </p>
      <p>
        <b>Security of Your Personal Information</b>
      </p>
      <p>
        The security of your Personal Information is important to us, but
        remember that no method of transmission over the Internet or method of
        electronic storage is 100% secure. While we strive to use commercially
        acceptable means to protect your Personal Information, we cannot
        guarantee its absolute security.
      </p>
      <p>
        <b>Children's Privacy</b>
      </p>
      <p>
        Our Service does not address anyone under the age of 18 ("Children"). We
        do not knowingly collect personally identifiable information from anyone
        under the age of 18. If you are a parent or guardian and you are aware
        that your Children have provided us with Personal Information, please
        contact us. If we become aware that we have collected Personal
        Information from children without verification of parental consent, we
        take steps to remove that information from our servers.
      </p>
      <p>
        <b>Changes to This Privacy Policy</b>
      </p>
      <p>
        We may update our Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page. You are
        advised to review this Privacy Policy periodically for any changes.
        Changes to this Privacy Policy are effective when they are posted on
        this page.
      </p>
      <p>
        <b>Contact Us</b>
      </p>
      <p>
        If you have any questions about this Privacy Policy, please contact us
        via email at privacy@leadsfinder.co.uk.
      </p>
    </Wrapper>
  );
};

export default Privacy;

const Wrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;

  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  }

  // mobile
  @media (max-width: 768px) {
    padding: 10px;
  }
`;
