import styled, { keyframes } from "styled-components";

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  span.loader {
    width: 48px;
    height: 48px;
    border: 3px solid #144985;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: ${rotation} 1s linear infinite;

    &.small {
      width: 15px;
      height: 15px;
      border: 2px solid #000;
    }
  }

  span.loader::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 3px solid;
    border-color: #ff3d00 transparent;
  }
  span.small::after {
    width: 20px;
    height: 20px;
    /* border: 2px solid;
      border-color: #ff3d00 transparent; */
  }
`;

export default Spinner;
