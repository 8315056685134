import { ArrowRightCircle } from "feather-icons-react/build/IconComponents";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const LinkButton = () => {
  return (
    <StyledLinkButton to={"/by-industry"}>
      Select target audience
      <ArrowRightCircle
        size={20}
        style={{ marginLeft: "10px", verticalAlign: "sub" }}
      />
    </StyledLinkButton>
  );
};

export default LinkButton;

const StyledLinkButton = styled(Link)`
  position: relative;
  top: 0;
  width: 260px;
  border-radius: 50px;
  border: 1px solid lightgray;
  padding: 5px 15px;
  /* background-color: rgb(155 190 255); */
  background-color: rgba(0, 120, 231, 0.78);
  font-size: 1rem;
  font-weight: bold;
  margin: 20px auto;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.15);

  transition: all 0.2s ease-in-out;

  color: #fff !important;
  text-shadow: 0px 0px 3px #00000063;

  &:hover {
    background-color: rgb(112 156 239);
    top: 2px;
    // make the button appear to be pressed down
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  }

  // mobile

  @media (max-width: 768px) {
    width: 80%;
  }
`;
