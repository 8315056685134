import React, { useState, useEffect } from "react";
import { Link, ScrollRestoration } from "react-router-dom";
import logo from "./logo_200.png";
import styled from "styled-components";
import { supabase } from "./supabase";
import CompanyCard from "./components/companyCard";
import LandingPageContent from "./pages/landingPageContent";
import { isMember } from "./helpers/userHelpers";
// import HamburgerMenu from "./components/hamburger";
import useUserStore from "./stores/userStore";
import Spinner from "./components/spinner";
import useConfigStore from "./stores/serverConfigStore";
// import SicDropdown from "./components/sicDropdown";

function App(props) {
  const [companies] = useState([]);
  const [isLoading] = useState(false);
  const [errorMessage] = useState(null);

  const { user, setUser, removeUser, setUserFilterSubscriptions } =
    useUserStore();

  const { configs, setConfigs } = useConfigStore();

  // Find if current TLD is .co.uk or .uk
  let currentTLD = window.location.hostname.split(".").pop();
  if (currentTLD === "uk") {
    currentTLD = "co.uk";
  }

  const getCurrentSession = async (session) => {
    if (!session) {
      return;
    }

    if (session.user) {
      // const { access_token, refresh_token } = session;

      // localStorage.setItem("accessToken", access_token);
      // localStorage.setItem("refreshToken", refresh_token);

      const { error, data } = await supabase
        .from("users")
        .select("*")
        .eq("uid", session.user.id)
        .single();

      if (error) {
        if (session.user) {
          await supabase.from("users").insert([
            {
              uid: session.user.id,
              email: session.user.email,
              created_at: new Date(),
            },
          ]);

          const { data: newUser, error: newErr } = await supabase
            .from("users")
            .select("*")
            .eq("uid", session.user.id)
            .single();

          if (newErr) {
            console.log(newErr);
            return;
          }

          setUser(newUser);
        }
        console.log("NUINDB");
        return null;
      } else {
        // fetch user's subscriptions and set them in the store
        const { data: subscriptions, error: subscriptionsError } =
          await supabase
            .from("user_companies_watch")
            .select("*")
            .eq("user_id", session.user.id);

        if (!subscriptionsError) {
          setUserFilterSubscriptions(subscriptions);
        }

        setUser(data);
        return data;
      }
    }
  };

  useEffect(() => {
    // Fetch service_config table from supabase and check for active MAINTENANCE_MODE
    const fetchServiceConfig = async () => {
      let { data: service_configs, error } = await supabase
        .from("service_config")
        .select("*");

      if (error) {
        console.log(error);
        return;
      }

      setConfigs(service_configs);
    };

    fetchServiceConfig();

    // Listen for database changes in the service_config table,
    // check if there is a row with value MAINTENANCE_MODE with active true,
    // if so set isUpdating to true
    const channel = supabase.channel("table_db_changes").on(
      "postgres_changes",
      {
        event: "*",
        schema: "public",
        table: "service_config",
      },

      (payload) => {
        // console.log("Change received!", payload);
        // check if configs has one with same id, if so update it
        if (configs.length) {
          const existing = configs.find(
            (config) => config.id === payload.new.id
          );

          if (existing) {
            const newConfigs = configs.filter(
              (config) => config.id !== payload.new.id
            );

            setConfigs([...newConfigs, payload.new]);
          } else {
            setConfigs([...configs, payload.rnew]);
          }
        } else {
          setConfigs([payload.new]);
        }
      }
    );
    channel.subscribe();

    // Check current supabase auth state
    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      // console.log(event, session);

      if (event === "INITIAL_SESSION") {
        // getCurrentSession();

        if (session && session.user && !user) {
          getCurrentSession(session);
        }
      } else if (event === "SIGNED_IN") {
        const { access_token, refresh_token } = session;
        localStorage.setItem("accessToken", access_token);
        localStorage.setItem("refreshToken", refresh_token);

        getCurrentSession(session);
      } else if (event === "SIGNED_OUT") {
        localStorage.clear();
        removeUser();
      } else if (event === "PASSWORD_RECOVERY") {
        // handle password recovery event
      } else if (event === "TOKEN_REFRESHED") {
        // handle token refreshed event
        // const { access_token, refresh_token } = session;
        // localStorage.setItem("accessToken", access_token);
        // localStorage.setItem("refreshToken", refresh_token);
        // getCurrentSession();
      } else if (event === "USER_UPDATED") {
        // handle user updated event

        getCurrentSession(session);
      }
    });

    return () => {
      data.subscription.unsubscribe();
      channel.unsubscribe();
    };
  }, []);

  const renderCompanies = () => {
    if (!isLoading) {
      return (
        <CompanyList>
          {companies.map((company) => (
            <li key={company.CompanyNumber}>
              <CompanyCard key={company.CompanyNumber} company={company} />
            </li>
          ))}
        </CompanyList>
      );
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Spinner>
          <span className="loader"></span>
        </Spinner>
      );
    }

    if (props.child) {
      return props.child;
    }

    if (errorMessage) {
      return <ErrorMessage>{errorMessage}</ErrorMessage>;
    }

    if (companies.length) {
      return renderCompanies();
    }

    return <LandingPageContent />;
  };

  return (
    <Wrapper>
      <Header>
        <div>
          <Link to="/">
            <BrandName>
              <Logo src={logo} alt="logo" />
              <span className="">Leads</span>
              <span className="thin">Finder</span>
              <span className="xs">.{currentTLD}</span>
            </BrandName>
          </Link>

          {/* <form onSubmit={searchCompanies}>
            <SearchBar
              type="search"
              name="name"
              placeholder="Search by name or company number"
            />
            <SubmitBtn type="submit" value="Search" disabled={isLoading} />
          </form> */}

          {/* <Link className="by-industry-link" to="/by-industry">
            Search by industry
          </Link> */}
        </div>

        <div>
          {/* <HamburgerMenu /> */}

          <Link className="right-nav-link howitworks" to={"/how-it-works"}>
            How it works
          </Link>
          {!user && (
            <Link className="right-nav-link pricing" to={"/pricing"}>
              Pricing
            </Link>
          )}
          {/* <Link className="right-nav-link" to={"/how-it-works"}>
            How it works
          </Link> */}
          {!user && (
            <Link className="right-nav-link" to={"/login"}>
              Join / Sign In
            </Link>
          )}
          {user && (
            <Link className="right-nav-link" to={"/account"}>
              My Account
            </Link>
          )}
        </div>
      </Header>

      <Row>
        <Main>{renderContent()}</Main>
      </Row>

      <Footer>
        <Column>
          {/* <a href="https://www.leadsfinder.co.uk">LeadsFinder.co.uk</a> */}
          <Link to="/">
            <BrandName>
              <Logo src={logo} alt="logo" />
              <span className="">Leads</span>
              <span className="thin">Finder</span>
              <span className="xs">.{currentTLD}</span>
            </BrandName>
          </Link>

          <p>
            Automatic lead generation for B2B sales. Export lists of existing
            companies, and get notified when new companies are registered in
            your industry.
          </p>
        </Column>

        <Column>
          <h3>Main categories</h3>

          <LinkList>
            <Link to="/by-industry/a-agriculture-forestry-and-fishing">
              Agriculture, Forestry and Fishing
            </Link>

            <Link to="/by-industry/c-manufacturing">Manufacturing</Link>

            <Link to="/by-industry/f-construction">Construction</Link>

            <Link to="/by-industry/h-transportation-and-storage">
              Transportation and Storage
            </Link>

            <Link to="/by-industry/i-accommodation-and-food-service-activities">
              Accommodation and Food Service Activities
            </Link>

            <Link to="/by-industry/j-information-and-communication">
              Information and Communication
            </Link>

            <Link to="/by-industry/l-real-estate-activities">
              Real Estate Activities
            </Link>

            <Link to="/by-industry/p-education">Education</Link>

            <Link to="/by-industry/r-arts-entertainment-and-recreation">
              Arts, Entertainment and Recreation
            </Link>

            <Link to="/by-industry">
              <b>See all categories</b>
            </Link>
          </LinkList>
        </Column>

        <Column>
          <h3>Links</h3>

          <LinkList>
            {user ? (
              <Link to="/account">My Account</Link>
            ) : (
              <Link to="/login">Join / Sign In</Link>
            )}

            <Link to="/how-it-works">How it works</Link>
            <Link to="/whats-my-sic-code">What's my SIC code?</Link>
            <Link to="/what-is-sic-codes">What is a SIC code?</Link>
            <Link to="/pricing">Pricing</Link>
            <Link to="/about">About</Link>
          </LinkList>
          <h3>Legal</h3>

          <LinkList>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/terms">Terms & Conditions</Link>
          </LinkList>
        </Column>
      </Footer>

      <ScrollRestoration />
    </Wrapper>
  );
}

export default App;

// Styled Components

// Wrapper for the whole app
const Wrapper = styled.div`
  height: 100vh;

  a,
  a:visited,
  a:hover,
  a:active {
    color: #193a58;
    text-decoration: none;
  }
`;

const Header = styled.header`
  /* grid-area: header; */
  padding: 6px 20px;
  color: #193a58;
  text-align: left;
  display: flex;
  align-items: center;
  /* background-color: #eaf3ff; */
  background-color: #fff;

  div {
    display: flex;
    align-items: center;
  }

  justify-content: space-between;

  img {
    margin: 0px 0px 0px 20px;
  }

  a,
  a:visited,
  a:hover,
  a:active {
    color: #193a58;
    text-decoration: none;
  }

  a.by-industry-link {
    margin-left: 20px;
  }

  a.right-nav-link {
    font-weight: bold;
    margin-right: 40px;

    &:hover {
      color: #000;
      text-decoration: underline;
    }

    @media (max-width: 768px) {
      margin-right: 5px;
      font-size: 0.8rem;

      &.pricing {
        display: none;
      }
      &.howitworks {
        display: none;
      }
    }
  }

  // mobile, make form flex

  @media (max-width: 768px) {
    img {
      margin: 0px 0px 0px 0px;
    }
    form {
      display: flex;
    }
  }
`;

const Logo = styled.img`
  width: 40px;
  height: 40px;
  vertical-align: middle;

  // mobile

  @media (max-width: 768px) {
    margin: 0px 10px 0px 0px;
  }
`;

const BrandName = styled.h1`
  font-size: 1.4rem;
  font-family: "Poppins", sans-serif;
  font-style: italic;

  span.thin {
    font-weight: 500;
  }

  span.xs {
    font-size: 0.7rem;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  // Hide span on mobile
  /* @media (max-width: 768px) {
    span {
      display: none;
    }
  } */
`;

// Row for the sidebar and main content area
const Row = styled.div`
  display: flex;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

// search bar, in the style of google search bar
// const SearchBar = styled.input`
//   width: 300px;
//   height: 40px;
//   border-radius: 5px 0px 0px 5px;
//   border: 1px solid lightgray;
//   padding: 0 10px;
//   font-size: 1rem;
//   margin-left: 10px;
//   outline: none;

//   // modile

//   @media (max-width: 768px) {
//     width: 100%;
//     margin-left: 0px;
//     border-radius: 5px;
//   }
// `;

// const SubmitBtn = styled.input`
//   width: 100px;
//   height: 40px;
//   border-radius: 0px 5px 5px 0px;
//   border: 1px solid lightgray;
//   padding: 0 10px;
//   background-color: white;
//   font-size: 1rem;
//   /* margin-left: 10px; */
//   margin: 0 auto;
//   cursor: pointer;

//   &:hover {
//     background-color: lightgray;
//   }
// `;

// Main content area

const Main = styled.div`
  /* width: 100%; */
  min-height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  /* background-color: #eeeeee; */
  background-color: #fff;

  // mobile
  @media (max-width: 768px) {
    padding: 20px 10px;
    display: inline-block;
    width: auto;
  }
`;

export const CompanyList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 auto;
  text-align: left;
  width: 100%;

  li {
    width: 100%;
    margin: 0 auto;
    background-color: #eaf3ff;
    border-radius: 5px;
    /* padding: 20px; */
    margin-bottom: 20px;
  }
`;

const ErrorMessage = styled.p`
  color: #bb3434;
  font-size: 1.2rem;
  font-weight: bold;
`;

// Footer
const Footer = styled.footer`
  flex: 1;
  padding: 55px 25px;
  background-color: #000000de;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  margin-top: 150px;

  ${Row} {
    margin: 0;
    padding: 0;
    justify-content: space-between;
  }

  // right align the last column
  ${Column} {
    flex: 1;

    padding: 0 20px;

    max-width: 300px;

    /* &:last-child {
      text-align: right;
    } */
  }

  // black and white logo
  ${BrandName} {
    font-size: 1.2rem;
    margin: 0;

    filter: grayscale(100%);
    transition: filter 0.3s;

    &:hover {
      filter: grayscale(0%);
    }
  }

  a,
  a:visited,
  a:active {
    color: rgba(255, 255, 255, 0.7);
  }

  a:hover {
    text-decoration: underline;
    color: #fff;
  }

  // mobile
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 10px;
    margin-top: 50px;

    ${Row} {
      flex-direction: column;
    }

    ${Column} {
      margin-bottom: 20px;

      &:last-child {
        text-align: left;
      }
    }
  }
`;

const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  a {
    margin: 5px 0;
  }
`;

// const StyledStar = styled(Star)`
//   /* margin: 0 5px; */
//   fill: #22c55e;
//   stroke: #22c55e;
// `;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  margin: 20px 0;
`;
