import React from "react";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import LinkButton from "../components/buttons/linkButton";
import { Check, Star } from "feather-icons-react/build/IconComponents";

import alex from "../assets/images/alexander.jpg";
import eric from "../assets/images/eric.jpg";
import oscar from "../assets/images/oscar.jpg";
import per from "../assets/images/per.jpg";
import taher from "../assets/images/taher.jpg";
import TextLooper from "../components/atoms/textLooper";

import browser from "../assets/images/browser.png";

const LandingPageContent = () => {
  const renderStats = () => {
    return (
      <InfoRow>
        <TextLooper
          texts={[
            "All <b>5,621,958</b> UK companies",
            "<b>5,235</b> new companies last week",
            "<b>51,041</b> new companies last month",
          ]}
        />
      </InfoRow>
    );
  };

  return (
    <Container>
      <Header>
        B2B Lead generation on <span>autopilot</span>
      </Header>
      <SubHeader>
        Newly formed companies across the UK, within your specific industry
      </SubHeader>

      <p>
        Delivered <b>weekly</b>
      </p>

      <LinkButton />

      <SocialProofRow>
        <img src={alex} alt="alex" />
        <img src={oscar} alt="oscar" />
        <img src={per} alt="per" />
        <img src={taher} alt="taher" />
        <img src={eric} alt="eric" />

        <StarWrapper>
          <StyledStar size={15} color="#ffeb3b" />
          <StyledStar size={15} color="#ffeb3b" />
          <StyledStar size={15} color="#ffeb3b" />
          <StyledStar size={15} color="#ffeb3b" />
          <StyledStar size={15} color="#ffeb3b" />
        </StarWrapper>
      </SocialProofRow>

      {renderStats()}

      <BrowserImage src={browser} alt="browser" />

      <CardsWrapper>
        <Card>
          <CardHeader>
            <Check size={20} style={{ marginRight: "10px" }} />
            Weekly insights
          </CardHeader>

          <CardBody>
            <p>
              Never miss out on emerging opportunities in your industry. Get
              weekly updates on the latest UK company formations tailored to
              your specific interests and business needs.
            </p>

            <p>
              Our data comes to you as soon as it's available on the market, so
              you can be the first to reach out. Start every week with a fresh
              list of potential leads.
            </p>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <Check size={20} style={{ marginRight: "10px" }} />
            Customizable
          </CardHeader>

          <CardBody>
            <p>
              Easily find established or new companies that match your criteria.
              Filter by industry, location, and registration date to pinpoint
              the most relevant businesses for your needs, whether you're
              seeking partners, clients, or market intelligence.
            </p>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <Check size={20} style={{ marginRight: "10px" }} />
            Actionable Data
          </CardHeader>

          <CardBody>
            <p>
              Stay ahead of the curve with weekly updates on newly registered UK
              companies in your specific industry. Our platform uses{" "}
              <Link to="/what-is-sic-codes">SIC codes</Link> to deliver precise,
              relevant information directly to your inbox every Monday.
            </p>
          </CardBody>
        </Card>
      </CardsWrapper>

      {/* {renderPricingOnMobile()} */}

      {/* <PricingWrapper>
        <Pricing tagline={"It's simple"} />
      </PricingWrapper> */}

      <LearnMoreLink to={"/how-it-works"}>See how it works</LearnMoreLink>
    </Container>
  );
};

export default LandingPageContent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 50px;
`;

// keyframes for animated gradient text
const animatedgradient = keyframes`

  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
  `;

const Header = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin: 20px auto 0;
  font-weight: bold;

  span {
    /* color: #193a58; */

    // animated gradient text

    background: -webkit-linear-gradient(45deg, #680aca, #ff897e);
    background: linear-gradient(
      60deg,
      #f79533,
      #f37055,
      #ef4e7b,
      #a166ab,
      #5073b8,
      #1098ad,
      #07b39b,
      #6fba82
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-animation: ${animatedgradient} 6s ease infinite alternate;
    animation: ${animatedgradient} 6s ease infinite alternate;
    background-size: 300% 300%;
    border: 1px solid rgba(0, 0, 0, 0);
    color: transparent;
  }

  @media (max-width: 768px) {
    text-align: center;
    font-size: 2.9rem;
    line-height: 2.9rem;
  }
`;

const SubHeader = styled.h2`
  text-align: center;
  width: 100%;
  font-size: 1.6rem;
  margin: 0 auto 20px;
  font-weight: 500;
  /* color: #575757; */

  span {
    /* color: #000; */
    font-weight: bold;
  }

  // auto scale text size on mobile

  @media (max-width: 768px) {
    font-size: 1rem;
    width: 80%;
    text-align: center;
  }
`;

const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  // auto scale on mobile

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card = styled.div`
  width: 100%;
  max-width: 600px;
  padding: 20px;

  display: flex;
  flex-direction: column;

  background-color: white;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const CardHeader = styled.h3`
  margin: 0%;
  font-size: 1.5rem;
  font-weight: 500;
`;

const CardBody = styled.div`
  p {
    font-size: 1rem;
    color: rgb(0 0 0 / 70%);
  }

  a {
    color: #193a58;
    font-weight: 500;
    text-decoration: underline;
  }
`;

// const CardWrapperHeader = styled.div`
//   text-align: left;
//   margin-top: 50px;

//   h2 {
//     font-size: 2rem;
//     margin: 0;
//     /* font-family: "Playfair Display", serif; */
//     font-family: "Poppins", sans-serif;
//     font-weight: 500;
//   }
// `;

const LearnMoreLink = styled(Link)`
  margin-top: 50px;
  color: #193a58;
  font-weight: 500;
  font-size: 1.2rem;

  &:hover {
    text-decoration: underline !important;
  }
`;

const CardsWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  max-width: 1200px;

  // auto scale on mobile

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const InfoRow = styled.div`
  margin-top: 15px;
  display: flex;
  font-size: 13px;
  opacity: 0.7;

  span {
    margin-left: 10px;
    svg {
      vertical-align: sub;
      margin-right: 5px;
    }
  }

  // mobile
  @media (max-width: 900px) {
    display: none !important;
  }
`;

const StarWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;

const StyledStar = styled(Star)`
  /* color: #22c55e; */
  fill: #ffeb3b;
`;

const SocialProofRow = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: -14px;
    border: 1px solid #fff;
  }
`;

const BrowserImage = styled.img`
  width: 100%;
  margin-top: 50px;
  max-width: 800px;
`;
