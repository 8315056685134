import React, { useEffect, useState } from "react";
import { supabase } from "../supabase";
import styled from "styled-components";
import useUserStore from "../stores/userStore";
import { isMember } from "../helpers/userHelpers";
import sicList from "../data/sicList";
import moment from "moment";
import {
  ExternalLink,
  Info,
  Users,
} from "feather-icons-react/build/IconComponents";
import PrimaryButton from "../components/buttons/primaryButton";

const SicList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    padding: 3px 0;
  }
`;

const CompanyPage = () => {
  const { user } = useUserStore();
  const [company, setCompany] = useState(null);
  const [officers, setOfficers] = useState({});
  const [isFetchingOfficers, setIsFetchingOfficers] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const companyNumber = window.location.pathname.split("/")[2];
    fetchCompany(companyNumber);
  }, []);

  const fetchCompany = async (companyNumber) => {
    const endpoint =
      "https://api.leadsfinder.co.uk/functions/v1/company-by-number";

    const body = {
      companyNumber,
    };

    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const { data, error } = await response.json();

    if (error) {
      console.log(error);
      return;
    }

    setCompany(data);
  };

  const findSicByCode = (code) => {
    // Iterate over all the categories in the list
    const foundItems = sicList.flatMap((category) =>
      category.items.filter((item) => item.startsWith(code))
    );

    return foundItems;
  };

  const renderSicCodes = (company) => {
    const sicCodes = [];

    for (let i = 1; i <= 4; i++) {
      if (company.sic_codes[i]) {
        sicCodes.push(
          <li key={i}>
            <span>{findSicByCode(company.sic_codes[i])}</span>
          </li>
        );
      }
    }

    return (
      <div>
        <b>SIC codes:</b>
        <SicList>{sicCodes}</SicList>
      </div>
    );
  };

  const fetchOfficers = async (company) => {
    setIsFetchingOfficers(true);

    try {
      const { data, error } = await supabase.functions.invoke(
        "fetch-officers",
        {
          body: { companyNumber: company.company_number },
        }
      );

      if (error) {
        console.log(error);
        return;
      }

      // OFFICERS RESPONSE
      //   {
      //     "kind": "officer-list",
      //     "inactive_count": 0,
      //     "etag": "a1064d9c519dcd3783ccfa46bf4c09e6c23b4b93",
      //     "resigned_count": 0,
      //     "total_results": 2,
      //     "items_per_page": 35,
      //     "items": [
      //         {
      //             "officer_role": "secretary",
      //             "name": "ANDERSON, Raymond",
      //             "address": {
      //                 "postal_code": "BB1 2QY",
      //                 "country": "England",
      //                 "locality": "Blackburn",
      //                 "premises": "B1 Business Centre, Suite 206",
      //                 "address_line_1": "Davyfield Road"
      //             },
      //             "appointed_on": "2024-01-31",
      //             "links": {
      //                 "self": "/company/15457231/appointments/ckYqkXEMO10JDWpETde5a3nrICw",
      //                 "officer": {
      //                     "appointments": "/officers/Z8v4xZuXhezdAa0eiWFvxse3lAE/appointments"
      //                 }
      //             }
      //         },
      //         {
      //             "nationality": "British",
      //             "address": {
      //                 "premises": "B1 Business Centre, Suite 206",
      //                 "country": "England",
      //                 "postal_code": "BB1 2QY",
      //                 "address_line_1": "Davyfield Road",
      //                 "locality": "Blackburn"
      //             },
      //             "appointed_on": "2024-01-31",
      //             "name": "ANDERSON, Raymond",
      //             "occupation": "Design & Construction Director",
      //             "links": {
      //                 "officer": {
      //                     "appointments": "/officers/S1wHVW9_BV-YB7qgcEiipUZSgFM/appointments"
      //                 },
      //                 "self": "/company/15457231/appointments/R0nyAJWOnvYU8pQUA5Y_YR4wxAo"
      //             },
      //             "date_of_birth": {
      //                 "month": 7,
      //                 "year": 1952
      //             },
      //             "officer_role": "director",
      //             "country_of_residence": "United Kingdom"
      //         }
      //     ],
      //     "links": {
      //         "self": "/company/15457231/officers"
      //     },
      //     "start_index": 0,
      //     "active_count": 2
      // }

      setOfficers(data);
      setIsFetchingOfficers(false);
    } catch (error) {
      console.log(error);
      setIsFetchingOfficers(false);

      setError("Something went wrong. Please try again, or contact support.");
    }
  };

  const renderOfficers = () => {
    return (
      <OfficersInfoWrapper>
        <h2>
          <Users /> Officers
        </h2>

        {!isMember(user) ? (
          <InfoMessage>
            <Info />
            Full membership required to view officers.
          </InfoMessage>
        ) : (
          <div>
            {
              // If fetching officers, show a spinner
              isFetchingOfficers && <p>Loading officers...</p>
            }

            {
              // if list of officers is not empty, hide the button
              officers.items === undefined && (
                <PrimaryButton
                  disabled={
                    !isMember(user) ||
                    isFetchingOfficers ||
                    officers.items !== undefined
                  }
                  onClick={() => fetchOfficers(company)}
                >
                  View officers
                </PrimaryButton>
              )
            }

            {
              // If the officers list is empty, show a message
              officers.items !== undefined && officers.items.length === 0 && (
                <p>No officers found for this company.</p>
              )
            }

            {officers.items !== undefined &&
              officers.items.map((officer, index) => {
                return (
                  <OfficerCard key={index}>
                    <b>
                      {officer.officer_role
                        ? officer.officer_role.toUpperCase()
                        : "Role not stated"}
                    </b>

                    <OfficerName>{officer.name}</OfficerName>

                    <p>Occupation: {officer.occupation ?? "Not stated"}</p>
                    {officer.date_of_birth && (
                      <p>
                        Age:
                        {moment().diff(
                          `${officer.date_of_birth.year}-${officer.date_of_birth.month}`,
                          "years"
                        )}{" "}
                      </p>
                    )}

                    <p>Appointed on: {officer.appointed_on}</p>

                    <OfficerMoreInfo>
                      <u>Address: </u>
                      <OfficerAddress>
                        <p>{officer.address.premises}</p>
                        <p>{officer.address.address_line_1}</p>
                        <p>{officer.address.locality}</p>
                        <p>{officer.address.postal_code}</p>
                        <p>{officer.address.country}</p>
                      </OfficerAddress>
                    </OfficerMoreInfo>
                  </OfficerCard>
                );
              })}
          </div>
        )}
      </OfficersInfoWrapper>
    );
  };

  const renderCompanyInfo = (company) => {
    console.log(company);

    return (
      <CompanyDetailsCard>
        <CompanyNumber>{company.company_number}</CompanyNumber>
        <CompanyName>{company.company_name}</CompanyName>

        <p>
          {company.registered_office_address.address_line_1}
          <br />
          {company.registered_office_address.postal_code}
          <br />
          {company.registered_office_address.locality}
          <br />
          {company.registered_office_address.country}
        </p>

        {renderSicCodes(company)}

        <br />

        {renderOfficers()}
      </CompanyDetailsCard>
    );
  };

  return company ? renderCompanyInfo(company) : <div>Loading info...</div>;
};

export default CompanyPage;

const CompanyDetailsCard = styled.div`
  max-width: 700px;
  width: 100%;

  background: #fff;
  padding: 2%;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px hsl(0deg 0% 0% / 11%);
  margin-top: 20px;

  // mobile
  @media (max-width: 600px) {
    box-shadow: none;
    border: none;
    padding: 5%;
    width: auto;
  }
`;

const CompanyNumber = styled.span`
  font-size: 0.8rem;
  color: #999;

  @media (min-width: 768px) {
    font-size: 1rem;
  }
`;

const CompanyName = styled.h1`
  font-size: 1.5rem;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;

// Officer cards should have a header with the officer role, then the officer's name, age, and date of appointment in the body, and address in the footer.
const OfficersInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

const OfficerName = styled.p`
  font-weight: 500;
`;

const OfficerCard = styled.div`
  background: #fff;
  padding: 2%;
  border-radius: 5px;
  box-shadow: 0px 0px 5px 0px hsl(0deg 0% 0% / 11%);
  margin-top: 20px;

  p {
    margin: 0;
    padding: 0;
  }

  ${OfficerName} {
    margin-bottom: 10px;
  }

  button {
    margin-top: 10px;
  }
`;

const OfficerMoreInfo = styled.div`
  /* flex-direction: column; */
  margin-top: 15px;
  /* background: #ddd; */
  /* padding: 10px; */
  border-radius: 5px;
`;

const OfficerAddress = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoMessage = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  background: #f5f5f5;
  border-radius: 5px;
  margin-top: 20px;
  color: #333;

  svg {
    margin-right: 10px;
  }
`;
