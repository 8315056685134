import React from "react";
import styled from "styled-components";
import LinkButton from "../components/buttons/linkButton";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Bell, Download } from "feather-icons-react/build/IconComponents";

const Paragraph = styled.p`
  width: 550px;
  font-size: 1.3rem;
  /* margin-top: 50px; */
  line-height: 1.5;

  a {
    color: #ff897e;
    text-decoration: underline;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    width: 90%;
    /* margin: 15px auto; */
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 20px;

  h1 {
    margin: 0px;
  }

  h2 {
    margin-bottom: 0px;
  }

  svg {
    vertical-align: middle;
  }
`;

const HowItWorks = () => {
  return (
    <Container>
      <h1>How it works</h1>
      <Paragraph>
        Every week, thousands of new companies emerge across various industries
        in the UK. Our platform keeps you at the forefront of these market
        developments, helping you identify potential clients, partners, or
        competitors as they enter the scene.
      </Paragraph>
      <h2>Search the market</h2>
      <Paragraph>
        You can use our platform to find all the companies within a specific
        industry or location and filter them by the date they were registered.
        This way, you can easily find the most established companies within your
        target group or the ones that have been around for just the right amount
        of time.
      </Paragraph>
      <h2>
        <Download size={30} style={{ marginRight: "10px" }} />
        Export
      </h2>
      <Paragraph>
        With a Full Membership you can export the companies you find and use it
        for your marketing campaigns or to find potential partners and clients.
        You can also see information about the various directors of the
        companies and use it to find the right person to reach.
      </Paragraph>
      <h2>
        <Bell size={30} style={{ marginRight: "10px" }} />
        Weekly Updates Delivered to You
      </h2>
      <Paragraph>
        If you're looking for newcomers in the market, you can subscribe to a
        specific industry with the help of{" "}
        <Link to="/what-is-sic-codes">SIC codes</Link> and get weekly updates
        with all the newly registered companies in that industry and location.
      </Paragraph>

      <Paragraph>
        Every monday, you'll receive an email with all the new companies that
        match your criteria, including the company name, location, SIC code and
        more.
      </Paragraph>

      <h2
        style={{
          textAlign: "center",
        }}
      >
        Ready to get started?
      </h2>
      <LinkButton />
      <Helmet>
        <link rel="canonical" href={"https://leadsfinder.co.uk/how-it-works"} />
        <meta
          name="description"
          content="How our platform works, and how you can use it to find the most relevant companies for your business."
        />
      </Helmet>
    </Container>
  );
};

export default HowItWorks;
