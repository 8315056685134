import React, { useState } from "react";
import sicList from "../data/sicList";
import styled, { keyframes, css } from "styled-components";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";
import { ArrowLeft, Info } from "feather-icons-react/build/IconComponents";
import { SLUGS } from "../helpers/sicHelpers";
import AiSearch from "../components/aiSearch";

const ByCategory = (props) => {
  const [aiSearchQuery, setAiSearchQuery] = useState("");
  const [aiResults, setAiResults] = useState([]);

  const navigate = useNavigate();
  let selectedSection;

  const url = window.location.href;
  const letter = url.split("/").pop();

  if (letter.length > 0 && letter !== "by-industry") {
    selectedSection = sicList.find(
      (section) => section.letter === letter[0].toUpperCase()
    );
  }

  const currentSlug = SLUGS.find(
    (slug) => slug.letter === selectedSection?.letter.toLowerCase()
  );
  const currentUrl = `/by-industry/${currentSlug?.name || ""}`;

  const renderList = () => {
    if (selectedSection) {
      return (
        <SectionList>
          {selectedSection.items.map((item, index) => (
            <li
              key={index}
              onClick={() => {
                const slug = SLUGS.find(
                  (slug) => slug.letter === selectedSection.letter.toLowerCase()
                );

                return navigate(
                  `/by-industry/${slug.name}/sic/${item.match(/\d+/)[0]}`
                );
              }}
            >
              {item}
            </li>
          ))}
        </SectionList>
      );
    }

    const sections = sicList.map((section, index) => {
      return (
        <li
          key={index}
          onClick={() => {
            const slug = SLUGS.find(
              (slug) => slug.letter === section.letter.toLowerCase()
            );
            return navigate(`/by-industry/${slug.name}`);
          }}
        >
          <b>{section.letter}</b> - {section.description}
        </li>
      );
    });

    return <SectionList>{sections}</SectionList>;
  };

  let sectionText;
  if (selectedSection) {
    sectionText = SLUGS.find(
      (slug) => slug.letter === selectedSection.letter.toLowerCase()
    ).description;
  }
  // const dummyItems = [
  //   {
  //     id: 1953,
  //     item: "45112 - Sale of used cars and light motor vehicles",
  //     description:
  //       "Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles",
  //     letter: "G",
  //     slug: "g-wholesale-and-retail-trade-repair-of-motor-vehicles-and-motorcycles",
  //     similarity: 0.88885258303318,
  //   },
  //   {
  //     id: 1953,
  //     item: "45112 - Sale of used cars and light motor vehicles",
  //     description:
  //       "Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles",
  //     letter: "G",
  //     slug: "g-wholesale-and-retail-trade-repair-of-motor-vehicles-and-motorcycles",
  //     similarity: 0.88885258303318,
  //   },
  //   {
  //     id: 1953,
  //     item: "45112 - Sale of used cars and light motor vehicles",
  //     description:
  //       "Wholesale and Retail Trade; Repair of Motor Vehicles and Motorcycles",
  //     letter: "G",
  //     slug: "g-wholesale-and-retail-trade-repair-of-motor-vehicles-and-motorcycles",
  //     similarity: 0.88885258303318,
  //   },
  // ];

  const pageTitle = selectedSection
    ? `Find companies in ${selectedSection.description}`
    : "Find companies by SIC code";

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://leadsfinder.co.uk${currentUrl}`} />

        <title>{pageTitle} | LeadsFinder</title>
      </Helmet>
      <ListWrapper>
        {!selectedSection && (
          <>
            <h1>Main categories</h1>

            <h3>Select the main category of your target audience</h3>

            <AIWrapper>
              <p>Not sure? Describe what they do and let us find it for you</p>

              <AiSearch
                onNewResults={setAiResults}
                searchQuery={aiSearchQuery}
                onChange={(val) => setAiSearchQuery(val)}
              />

              {aiResults.length > 0 && (
                <SectionList fadein>
                  <h3>
                    ✨ {aiResults.length} categories based on your description
                  </h3>

                  {aiResults.map((result, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        const slug = SLUGS.find(
                          (slug) => slug.letter === result.letter.toLowerCase()
                        );

                        return navigate(
                          `/by-industry/${slug.name}/sic/${
                            result.item.match(/\d+/)[0]
                          }`
                        );
                      }}
                    >
                      {result.item}
                    </li>
                  ))}
                </SectionList>
              )}
            </AIWrapper>
          </>
        )}

        {selectedSection && (
          <>
            <SICHeader onClick={() => navigate("/by-industry")}>
              <ArrowLeft size={15} />
              Main categories
            </SICHeader>
            <h2>
              {selectedSection.letter} - {selectedSection.description}
            </h2>

            <InfoText>
              <Info size={20} />
              <div dangerouslySetInnerHTML={{ __html: sectionText }} />
            </InfoText>
          </>
        )}

        {renderList()}
      </ListWrapper>
    </>
  );
};

export default ByCategory;

const ListWrapper = styled.div`
  margin: 0 auto;
  width: 60%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h1,
    h2,
    h3 {
      text-align: center;
    }
  }
`;

const SICHeader = styled.h5`
  color: #797979;
  width: fit-content;
  cursor: pointer;

  &:hover {
    color: #000;
  }

  svg {
    cursor: pointer;
    vertical-align: middle;
    margin-right: 5px;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SectionList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;

  li {
    margin-bottom: 10px;
    cursor: pointer;
    /* width: 100%; */
    padding: 10px;
    border-radius: 5px;
    background-color: #eaeaea;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s;

    &:hover {
      background-color: lightgrey;
    }

    ${({ fadein }) =>
      fadein &&
      css`
        opacity: 0;
        -webkit-animation: ${fadeIn} 0.7s forwards;
        animation: ${fadeIn} 0.5s forwards;
        animation-delay: 0.5s;
      `}
  }
`;

const InfoText = styled.div`
  margin-top: 20px;
  padding: 10px;
  color: #797979;

  div {
    display: inline;
  }

  p {
    margin: 0;
    display: inline;
  }

  svg {
    vertical-align: middle;
    margin-right: 5px;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const AIWrapper = styled.div`
  margin-bottom: 40px;
  /* display: flex; */
  flex-direction: column;

  // check if isvisible is true
  display: flex;

  input,
  textarea {
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
    /* width: 300px; */
    border: 2px solid #000;
    height: 1rem;
    width: 200px;
    transition: all 1s;
  }

  textarea:focus {
    height: 50px;
    width: 250px;
  }

  button {
    display: flex;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #3a3a3a;
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #000;
    }
  }

  // mobile
  @media (max-width: 768px) {
    textarea:focus {
      height: 50px;
      width: 200px;
    }

    input {
      width: auto;
    }

    button: {
      width: 100%;
    }
  }
`;
