import React, { useState } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { Helmet } from "react-helmet";
import useUserStore from "../stores/userStore";

import { isMember } from "../helpers/userHelpers";

import PrimaryButton from "../components/buttons/primaryButton";
import { Check } from "feather-icons-react/build/IconComponents";
import { useNavigate } from "react-router-dom";

const Switch = styled.div`
  display: flex;
  /* justify-content: center; */
  margin-bottom: 20px;
`;

const SwitchButton = styled.button`
  padding: 10px 20px;
  background-color: #fff;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 1rem;
  margin-right: 10px;

  &:hover {
    background-color: #f9f9f9;
  }

  &.active {
    background-color: #635bff;
    color: #fff;
  }
`;

const SwitchButtonMonthly = styled(SwitchButton)`
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const SwitchButtonYearly = styled(SwitchButton)`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 20px;
  max-width: 600px;
  width: 100%;

  // mobile
  @media (max-width: 600px) {
    padding: 10px;
    width: auto;
  }
`;

const PaymentLink = styled.a`
  margin-top: 20px;
  display: inline-block;
  background-color: #635bff; /* Stripe's brand color, you can change this */
  color: white !important;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #4f46e5;
  }
`;

const SmallText = styled.p`
  font-size: 0.9rem;
  margin-top: 20px;
  color: #585858;
`;

const MembershipInfo = styled.div`
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  /* background: linear-gradient(
    129deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 6%,
    rgba(99, 91, 255, 1) 239%
  ); */
  width: 100%;
  /* max-width: 600px; */

  h3 {
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }

  svg {
    margin-right: 10px;
    vertical-align: middle;
    stroke: #635bff;
    stroke-width: 4;
  }

  // mobile
  @media (max-width: 600px) {
    width: auto;
  }
`;

const PricePlanPrice = styled.div`
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 10px;

  span {
    color: #635bff;
    font-size: 2.8rem;
  }
`;

const Pricing = ({ tagline }) => {
  const { user } = useUserStore();
  const navigate = useNavigate();

  const [activePlan, setActivePlan] = useState("yearly");

  const trackConversion = () => {
    console.log("GA");

    window.gtag("event", "conversion", {
      send_to: "AW-16465573982/28VeCKfO9JMZEN7wsqs9",
      transaction_id: "",
    });
  };

  const renderLink = () => {
    if (user && !isMember(user)) {
      if (activePlan === "monthly") {
        return (
          <PaymentLink
            href="#"
            target="_blank"
            rel="noopener noreferrer"
            onClick={(event) => {
              // prevent default and track conversion, then open link

              event.preventDefault();

              trackConversion();

              window.open(
                "https://buy.stripe.com/00g4h3fXi83ugHm006",
                "_blank"
              );
            }}
          >
            Get started
          </PaymentLink>
        );
      }

      return (
        <PaymentLink
          href="#"
          target="_blank"
          rel="noopener noreferrer"
          onClick={(event) => {
            // prevent default and track conversion, then open link

            event.preventDefault();

            trackConversion();

            window.open("https://buy.stripe.com/28obJv6mIbfGeze7sx", "_blank");
          }}
        >
          Get started
        </PaymentLink>
      );
    }

    if (user && isMember(user)) {
      return (
        <PrimaryButton onClick={() => navigate("/account")}>
          Get started
        </PrimaryButton>
      );
    }

    return (
      <PrimaryButton onClick={() => navigate("/login")}>
        Get started
      </PrimaryButton>
    );
  };

  const renderHelmet = () => {
    // This widget can both be its own page and a part of the landing page
    // If it's a part of the landing page, we don't want to render the Helmet
    // If currnet path is /pricing, we render the Helmet

    if (window.location.pathname === "/pricing") {
      return (
        <Helmet>
          <title>LeadsFinder.co.uk | Pricing</title>
          <meta
            name="description"
            content="Full access for just £49/year. Cancel anytime."
          />
          <link rel="canonical" href="https://leadsfinder.co.uk/pricing" />
        </Helmet>
      );
    }

    return null;
  };

  const _renderMonthlyOrYearlySwitch = () => {
    return (
      <Switch>
        <SwitchButtonYearly
          className={activePlan === "yearly" ? "active" : ""}
          onClick={() => setActivePlan("yearly")}
        >
          Yearly
        </SwitchButtonYearly>
        <SwitchButtonMonthly
          className={activePlan === "monthly" ? "active" : ""}
          onClick={() => setActivePlan("monthly")}
        >
          Monthly
        </SwitchButtonMonthly>
      </Switch>
    );
  };

  return (
    <Wrapper>
      {renderHelmet()}
      {/* <h1>We like it simple</h1> */}
      <h1>{tagline ?? "It's simple"}</h1>

      <MembershipInfo>
        <h3>Full Membership</h3>

        {_renderMonthlyOrYearlySwitch()}

        <PricePlanPrice>
          {activePlan === "monthly" ? (
            <>
              <span>£9</span> /month
            </>
          ) : (
            <>
              <span>£49</span> /year
            </>
          )}
        </PricePlanPrice>

        <p>
          <Check size="20" />
          Access full list of companies
        </p>
        <p>
          <Check size="20" />
          Access full list of officers
        </p>
        <p>
          <Check size="20" />
          Subscribe to filters, get automatic monthly exports
        </p>
        <p>
          <Check size="20" />
          Export lists of companies
        </p>
        <p>
          <Check size="20" />
          Customer support by humans
        </p>

        {activePlan === "yearly" && (
          <p>
            <Check size="20" />
            Save 55%
          </p>
        )}
        {renderLink()}
      </MembershipInfo>

      <SmallText>
        One price for all the data you need. Cancel anytime.
      </SmallText>
    </Wrapper>
  );
};

export default Pricing;
