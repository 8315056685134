import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router";
import { SLUGS } from "../helpers/sicHelpers";
import AiSearch from "../components/aiSearch";
import { Link } from "react-router-dom";

const WhatsMySic = (props) => {
  const [aiSearchQuery, setAiSearchQuery] = useState("");
  const [aiResults, setAiResults] = useState([]);
  const navigate = useNavigate();

  return (
    <div>
      <Helmet>
        <title>What's My SIC Code?</title>
        <meta
          name="description"
          content="Find your SIC code by describing what you do."
        />
        <link
          rel="canonical"
          href={"https://leadsfinder.co.uk/whats-my-sic-code"}
        />
      </Helmet>

      <AIWrapper>
        <h1>What's My SIC Code?</h1>
        <h3>
          Describe what you do and let us find the most relevant SIC codes for
          your business.
        </h3>

        <AiSearch
          onNewResults={setAiResults}
          searchQuery={aiSearchQuery}
          onChange={(val) => setAiSearchQuery(val)}
          placeholder="I sell fishing equipment"
        />

        {aiResults.length === 0 && (
          <p className="info-link">
            We will try and find the top 10 most relevant SIC codes matching
            your description. What is a SIC code?{" "}
            <Link to="/what-is-sic-codes">Learn more</Link>
          </p>
        )}

        {aiResults.length > 0 && (
          <SectionList fadein>
            <h3>✨ {aiResults.length} SIC codes based on your description</h3>

            {aiResults.map((result, index) => (
              <li
                key={index}
                onClick={() => {
                  const slug = SLUGS.find(
                    (slug) => slug.letter === result.letter.toLowerCase()
                  );

                  return navigate(
                    `/by-industry/${slug.name}/sic/${
                      result.item.match(/\d+/)[0]
                    }`
                  );
                }}
              >
                {result.item}
              </li>
            ))}
          </SectionList>
        )}
      </AIWrapper>
    </div>
  );
};

export default WhatsMySic;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const SectionList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;

  li {
    margin-bottom: 10px;
    cursor: pointer;
    /* width: 100%; */
    padding: 10px;
    border-radius: 5px;
    background-color: #eaeaea;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s;

    &:hover {
      background-color: lightgrey;
    }

    ${({ fadein }) =>
      fadein &&
      css`
        opacity: 0;
        -webkit-animation: ${fadeIn} 0.7s forwards;
        animation: ${fadeIn} 0.5s forwards;
        animation-delay: 0.5s;
      `}
  }
`;

const AIWrapper = styled.div`
  margin-bottom: 40px;
  /* display: flex; */
  flex-direction: column;

  // check if isvisible is true
  display: flex;

  h1 {
    margin-bottom: 0px;
  }

  p {
    max-width: 600px;
    font-size: 0.8rem;
    margin-top: 0;

    &.info-link {
      margin-top: 30px;
    }
    a {
      color: #000;
      text-decoration: underline;
    }
  }

  input,
  textarea {
    padding: 10px;
    border-radius: 5px;
    margin-right: 10px;
    /* width: 300px; */
    border: 2px solid #000;
    height: 1rem;
    width: 200px;
    transition: all 1s;
  }

  textarea:focus {
    height: 50px;
    width: 250px;
  }

  button {
    display: flex;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #3a3a3a;
    color: #fff;
    cursor: pointer;

    &:hover {
      background-color: #000;
    }
  }

  // mobile
  @media (max-width: 768px) {
    textarea:focus {
      height: 50px;
      width: 200px;
    }

    input {
      width: auto;
    }

    button: {
      width: 100%;
    }
  }
`;
