import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: left;
  line-height: 1.5;

  h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  // mobile

  @media (max-width: 768px) {
    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }
`;

const SicInfo = () => {
  return (
    <Wrapper>
      <h1>What is a SIC code?</h1>

      <p>
        Standard Industrial Classification (SIC) codes are a system for
        classifying industries by a four-digit code. Established in the United
        Kingdom, these codes are used to categorize businesses based on their
        main economic activities. The purpose of SIC codes is manifold, serving
        both governmental and statistical agencies in analyzing and maintaining
        economic data across different sectors of the economy.
      </p>

      <p>
        In the UK, the use of SIC codes is prevalent in various administrative
        setups, including, but not limited to, company registration, tax
        assessment, and employment statistics compilation. When a company is
        incorporated, it must choose a SIC code that best represents its primary
        business activity. This selection is crucial as it influences how the
        company is perceived by regulatory bodies and can impact tax
        liabilities, compliance obligations, and eligibility for government
        schemes.
      </p>

      <p>
        The classification system is structured hierarchically, with sections,
        divisions, groups, and classes. The broadest category, the section, is
        identified by a single letter (e.g., 'A' for Agriculture, Forestry and
        Fishing), and the most specific category, the class, is represented by a
        detailed four-digit code. This hierarchical structure allows for a high
        degree of specificity and precision in classifying a wide range of
        economic activities. SIC codes are periodically reviewed and updated to
        reflect changes in the economy, such as the emergence of new industries
        or the decline of others. The current version used in the UK is derived
        from the European Union's NACE system, ensuring compatibility and
        comparability with statistical data across member states. For
        businesses, understanding and selecting the appropriate SIC code is not
        just a regulatory requirement but also a strategic decision. It can
        influence perceptions among potential clients, competitors, and
        investors. Moreover, accurate classification is essential for government
        and researchers to track economic trends, identify growth sectors, and
        implement policy decisions effectively.
      </p>

      <Helmet>
        <title>What is a SIC code?</title>
        <meta
          name="description"
          content="Standard Industrial Classification (SIC) codes are a system for classifying industries by a four-digit code. Established in the United Kingdom, these codes are used to categorize businesses based on their main economic activities."
        />
        <link
          rel="canonical"
          href={"https://leadsfinder.co.uk/what-is-sic-codes"}
        />
      </Helmet>
    </Wrapper>
  );
};

export default SicInfo;
