import React, { useState } from "react";
import styled from "styled-components";
import LinkButton from "../components/buttons/linkButton";
import { Link } from "react-router-dom";
import Paragraph from "../components/atoms/paragraph";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 20px;

  h1 {
    margin: 0px;
  }

  h2 {
    margin-bottom: 0px;
  }
`;

const About = () => {
  return (
    <Container>
      <h1>About the service</h1>
      <Paragraph>
        Our platform helps you find all the companies within your target group,
        both established and newcomers. We offer a directory of every registered
        company in the UK across various industries, making it easy for you to
        identify the most relevant potential partners and clients for your
        business in a matter of seconds.
      </Paragraph>

      <h2>Stay up to date</h2>
      <Paragraph>
        Subscribe to a specific niche industry with the help of
        <Link to="/what-is-sic-codes">
          {" "}
          Standard Industrial Classification (SIC) codes
        </Link>
        , and get weekly updates with all the newly registered companies in that
        industry. Whether you're seeking collaborations, service providers, or
        new customers, our main goal with this service is to save you the time
        and effort to find them.
      </Paragraph>

      <Link to="/how-it-works">Read about how it works</Link>

      <LinkButton />
    </Container>
  );
};

export default About;
