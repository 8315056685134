import { create } from "zustand";

// check if FAKE_USER env var is set to true
// const FAKE_USER = {
//   uid: "77b8c6a5-a770-4e4a-a0c7-c5f28cbee4a2",
//   email: "ericintheloft@gmail.com",
//   paid_until: 2023349343125,
//   stripe_customer_id: "cus_QdwI4mmSZhJtLb",
// };

const useUserStore = create((set) => ({
  // user: FAKE_USER,
  user: null,
  setUser: (user) => set({ user }),
  removeUser: () => set({ user: null }),

  userFilterSubscriptions: [],
  setUserFilterSubscriptions: (userFilterSubscriptions) =>
    set({ userFilterSubscriptions }),
}));

export default useUserStore;

// userFilterSubscription:
// {
//   "uuid": "d3e3e3e3-3e3e-3e3e-3e3e-3e3e3e3e3e3e",
//   "user_id": "d3e3e3e3-3e3e-3e3e-3e3e-3e3e3e3e3e3e",
//   "city": "Stockholm",
//   "sic_text": "1234 foobar industry",
// }
