import styled from "styled-components";

const PrimaryButton = styled.button`
  position: relative;
  border-radius: 50px;
  border: 1px solid lightgray;
  padding: 10px 15px;
  background-color: #fff;
  font-size: 1rem;
  font-weight: bold;
  margin: 20px auto;
  /* text-align: center; */
  /* text-decoration: none; */
  color: #373737;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  svg {
    margin-right: 10px;
    vertical-align: middle;
  }

  &:hover {
    background-color: #ddd;
  }

  ${({ disabled }) =>
    disabled &&
    `
    color: #a0a0a0;
    background-color: #f0f0f0;
    cursor: pointer;
  `}

  ${({ warning }) =>
    warning &&
    `
    background-color: #f7bdbd;

    &:hover {
      background-color: #fc8383;
    }
  `}
  ${({ success }) =>
    success &&
    `
    background-color: #e8fff2;

    &:hover {
      background-color: #c0fad9;
    }
  `}

  ${({ danger }) =>
    danger &&
    `
    background-color: #ffeded;

    &:hover {
      background-color: #ff9b9b;
    }
  `}

  // xsmall

  ${({ xsmall }) =>
    xsmall &&
    `
    padding: 5px 10px;
    font-size: 0.8rem;
  `}




  // mobile

  @media (max-width: 768px) {
    width: 90%;
  }
`;

export default PrimaryButton;
