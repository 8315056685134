import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";
// import { BrowserRouter } from "react-router-dom";

import ByCategory from "./pages/byCategory";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import CompanyPage from "./pages/companyPage";
import ErrorPage from "./pages/errorPage";
import BySicCode from "./pages/bySicCode";
import About from "./pages/about";
import Signup from "./pages/signup";
import Login from "./pages/login";
import Account from "./pages/account";
import SicInfo from "./pages/sicInfo";
import Pricing from "./pages/pricing";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import HowItWorks from "./pages/howItWorks";
import WhatsMySic from "./pages/whatsMySic";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/by-industry",
    element: <App child={<ByCategory />} />,
  },
  {
    path: "/by-industry/:letter",
    element: <App child={<ByCategory />} />,
  },
  {
    path: "/by-industry/:letter/sic/:code",
    element: <App child={<BySicCode />} />,
  },
  {
    path: "/company/:companyNumber",
    element: <App child={<CompanyPage />} />,
  },
  {
    path: "/about",
    element: <App child={<About />} />,
  },
  {
    path: "/signup",
    element: <App child={<Signup />} />,
  },
  {
    path: "/login",
    element: <App child={<Login />} />,
  },
  {
    path: "/account",
    element: <App child={<Account />} />,
  },
  {
    path: "/what-is-sic-codes",
    element: <App child={<SicInfo />} />,
  },
  {
    path: "/pricing",
    element: <App child={<Pricing />} />,
  },

  {
    path: "/privacy-policy",
    element: <App child={<Privacy />} />,
  },
  {
    path: "/terms",
    element: <App child={<Terms />} />,
  },
  {
    path: "/how-it-works",
    element: <App child={<HowItWorks />} />,
  },
  {
    path: "/whats-my-sic-code",
    element: <App child={<WhatsMySic />} />,
  },

  {
    path: "/.well-known/acme-challenge/:challenge",
    element: <App />,
  },

  {
    path: "*",
    element: <App child={<ErrorPage />} />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <GoogleOAuthProvider clientId="348399017067-n595ghkv6fnahj7trocqrl3sjjsuqvqr.apps.googleusercontent.com">
  // </GoogleOAuthProvider>
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
